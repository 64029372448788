@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.meet-our-attorneys {
  .attorney {
    padding: 15px;
    background-color: $layoutWhite;
    @include box-shadow;
    border-radius: 5px;
    @media screen and (min-width: 768px) {
      padding: 30px;
      display: flex;
      flex-direction: row;
    }
    img {
      margin-bottom: 20px;
      width: 100%;
      max-width: 200px;
      @media screen and (min-width: 768px) {
        margin-bottom: unset;
        margin-right: 20px;
        width: 200px;
      }
    }
    .content {
      @extend %flex-column;
      justify-content: center;
    }
  }
}

.adam-hansen,
.shawn-garner,
.larry-deason {
  img {
    margin-bottom: 20px;
    width: 100%;
    max-width: 250px;
    @media screen and (min-width: 768px) {
      width: 250px;
    }
  }
}