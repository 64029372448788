@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.seminar-scheduler {
  h2 {
    font-weight: normal;
    letter-spacing: 0.03em;
  }
  .event-info {
    @include box-shadow;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    background-color: $layoutWhite;
    padding: 30px;
  }
}