@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.home {
  .podcast-teaser {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #b5cdc7;
    .content {
      @extend %container;
      @extend %flex-column;
      align-items: center;
      width: 100%;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
      }
      .title {
        display: flex;
        p {
          font-size: 18px;
        }
        @media screen and (min-width: 768px) {
          align-items: center;
          p {
            font-size: 20px;
          }
          @media screen and (min-width: 992px) {
            p {
              font-size: 22px;
              margin-left: 15px;
            }
          }
        }
      }
      button {
        color: #0f1b26;
        border: 3px solid #0f1b26;
        margin-top: 15px;
        @media screen and (min-width: 768px) {
          margin-top: 0px;
          margin-left: 25px;
        }
        &:hover {
          background-color: $disabledDark;
          border: none;
          color: $layoutWhite;
        }
      }
    }
  }
  .teaser {
    @media screen and (min-width: 768px) {
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../../assets/building.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      h1,
      h2 {
        color: $layoutWhite;
      }
    }
    .mobile-image {
      height: 280px;
      width: 100%;
      background-image: url("../../../assets/building.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .seminar {
    @extend %background-topography;
    svg {
      height: 30px;
    }
    .sections {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-evenly;
      }
      .section {
        align-self: center;
        max-width: 600px;
        @extend %flex-column;
        align-items: center;
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 50%;
          padding: 0px 20px;
        }
        .primary {
          margin: 0px 20px 20px;
          font-family: $sura;
          font-size: 18px;
          @media screen and (min-width: 768px) {
            font-size: 26px;
          }
        }
        p {
          text-align: center;
        }
        .appt-scheduler {
          @include box-shadow;
          background-color: $layoutWhite;
          border: 1px solid $subtle;
          border-radius: 5px;
          padding: 20px 10px;
          height: 700px;
          width: 100%;
          max-width: 500px;
          .title {
            font-size: 20px;
            margin-bottom: 20px;
          }
          @media screen and (min-width: 768px) {
            padding: 25px;
            .title {
              font-size: 26px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .service-teaser {
    background-image: url("../../../assets/bridge.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (min-width: 768px) {
      background-attachment: fixed;
      height: 600px;
    }
    .content-block {
      display: flex;
      align-items: center;
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      .service {
        @include box-shadow;
        background-color: $layoutWhite;
        border: 1px solid $subtle;
        border-radius: 5px;
        padding: 20px;
        width: 100%;
        max-width: 300px;
        text-align: center;
        margin-top: 20px;
        @media screen and (min-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 0px;
          padding: 24px;
          width: 32%;
          height: 350px;
          max-width: 340px;
        }
        @media screen and (min-width: 991px) {
          padding: 40px;
          height: 380px;
        }
        h3 {
          color: $darkBlue;
          font-size: 28px;
          @media screen and (min-width: 768px) {
            font-size: 28px;
          }
        }
        p {
          margin: 30px 0px;
          font-size: 18px;
        }
        button {
          font-size: 18px;
        }
      }
    }
  }
  .reviews {
    background-color: #d2dde1;
    h2 {
      align-self: center;
    }
    .review {
      @include box-shadow;
      background-color: $layoutWhite;
      border: 1px solid $subtle;
      border-radius: 5px;
      padding: 25px;
      @media screen and (min-width: 768px) {
        padding: 50px;
      }
      .text {
        font-family: $sura;
        font-size: 18px;
        font-style: italic;
      }
      .author {
        margin-top: 20px;
        font-size: 22px;
        font-weight: bolder;
        @media screen and (min-width: 768px) {
          margin-top: 40px;
        }
      }
    }
  }
  .team {
    background-color: $layoutWhite;
    .sections {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-evenly;
      }
      .section {
        align-self: center;
        padding: 0px 20px;
        max-width: 600px;
        @extend %flex-column;
        align-items: center;
        @media screen and (min-width: 768px) {
          width: 50%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .content-container {
    @extend %flex-centered;
  }
  .content-block {
    @extend %container;
    @extend %flex-column;
    width: 100%;
    margin: 30px 0px;
    padding: 20px;
    border-radius: 5px;
    justify-content: space-between;
    align-self: center;
    @media screen and (min-width: 768px) {
      margin: 60px 0px;
      padding: 25px;
      p {
        font-size: 22px;
      }
    }
    @media screen and (min-width: 991px) {
      margin: 100px 40px;
    }
    .header {
      @extend %flex-row-aligned;
      margin: 0px;
      font-size: 26px;
      font-family: $headingFont;
      font-weight: bold;
      line-height: 1.3;
      @media screen and (min-width: 768px) {
        font-size: 35px;
      }
      svg {
        margin-right: 15px;
        height: 30px;
      }
    }
  }
  .carousel.slide,
  .carousel-item,
  .carousel-inner {
    width: 100%;
    height: 100%;
  }
}