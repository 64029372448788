/* news-cycle-regular - latin */

@font-face {
  font-family: "News Cycle";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/news-cycle/news-cycle-v17-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/news-cycle/news-cycle-v17-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/news-cycle/news-cycle-v17-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
  url("../fonts/news-cycle/news-cycle-v17-latin-regular.woff") format("woff"), /* Modern Browsers */
  url("../fonts/news-cycle/news-cycle-v17-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../fonts/news-cycle/news-cycle-v17-latin-regular.svg#NewsCycle") format("svg");
  /* Legacy iOS */
  font-display: swap;
}


/* sura-regular - latin */

@font-face {
  font-family: "Sura";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/sura/sura-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/sura/sura-v13-latin-regular.eot") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/sura/sura-v13-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
  url("../fonts/sura/sura-v13-latin-regular.woff") format("woff"), /* Modern Browsers */
  url("../fonts/sura/sura-v13-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../fonts/sura/sura-v13-latin-regular.svg#Sura") format("svg");
  /* Legacy iOS */
  font-display: swap;
}


/* roboto-regular - latin */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-v29-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto/roboto-v29-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/roboto/roboto-v29-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
  url("../fonts/roboto/roboto-v29-latin-regular.woff") format("woff"), /* Modern Browsers */
  url("../fonts/roboto/roboto-v29-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../fonts/roboto/roboto-v29-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

$headingFont: "Roboto";
$bodyFont: "News Cycle";
$roboto: "Roboto";
$newsCycle: "News Cycle";
$sura: "Sura";
%header-text {
  font-weight: bold;
  letter-spacing: 0.05em;
}

%button-text {
  font-size: 16px;
  line-height: 16px;
}

%message {
  font-size: 16px;
  font-family: $bodyFont;
}

.heading-font {
  font-family: $headingFont !important;
}

.body-font {
  font-family: $bodyFont !important;
}

.sura {
  font-family: $sura !important;
}

.bold {
  font-weight: bolder !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-48 {
  font-size: 48px !important;
}