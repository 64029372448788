@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.meet-our-team {
  .team-member {
    padding: 15px;
    background-color: $layoutWhite;
    @include box-shadow;
    border-radius: 5px;
    @media screen and (min-width: 768px) {
      padding: 30px;
      display: flex;
      flex-direction: row;
    }
    img {
      margin-bottom: 20px;
      width: 250px;
      height: 100%;
      @media screen and (min-width: 768px) {
        margin-bottom: unset;
        margin-right: 30px;
      }
    }
    h2 {
      color: $layoutBlack;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .title {
      font-family: $newsCycle;
      color: $darkBlue;
      font-weight: bolder;
    }
    a {
      font-family: $sura;
    }
  }
}