@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.reviews {
  .platform {
    @extend %flex-column-centered;
    margin-left: 20px;
    cursor: pointer;
    svg {
      margin: 0px;
      width: 50px;
      height: 50px;
    }
    @media screen and (min-width: 768px) {
      margin-left: 35px;
      svg {
        width: 75px;
        height: 75px;
      }
    }
  }
}