$layoutBlack: #0f1b26; //0f1b26; #896b55
$layoutDark: #d7c9aa; //#d7c9aa; //#9b7e46; //0f1b26;
$layoutWhite: #fcfbf9;
$layoutGray: #f3f3f3;
$layoutGreen: #add3c5;
$layoutOrange: #b5673b;
$layoutOne: #4b4237;
$layoutTwo: #b5673b;
$layoutThree: #0b7a75;
$interactiveGreen1: #244e44;
$interactiveGreen2: #30685a;
$interactiveGreen3: #b5673b; //#736b60; //#3c8271; //#2d8269 - high contrast ratio option
$interactiveGreen4: #30685a;
$interactiveGreen5: #30685a;
$disabledBackground: #dcdcdc;
$disabledLight: #928d86;
$disabledDark: #3f3f3f;
$darkBlue: #19535f; //718F94
$attention: #00a17a;
$error: #d64141;
$subtle: #d5d3d3;
.background-white {
  background-color: $layoutWhite;
}

.background-black {
  background-color: $layoutBlack;
}

.background-gray {
  background-color: $layoutGray;
}

.background-green {
  background-color: $layoutGreen;
}

.background-orange {
  background-color: $layoutOrange;
}

.background-topography {
  @extend %background-topography;
}

%background-topography {
  background-color: #e4dccd;
  background-image: url("../assets/topography.svg");
}

.attention {
  color: $attention;
}

.red {
  color: $error;
}

.blue {
  color: $darkBlue;
}

.gray {
  color: $disabledLight;
}

.dark-gray {
  color: $disabledDark;
}

.black {
  color: $layoutBlack;
}

.white {
  color: $layoutWhite;
}

.subtle {
  color: $subtle;
}