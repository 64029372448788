@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.address {
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 15px;
    width: 25px;
    align-self: center;
  }
  p {
    font-family: $roboto;
    font-weight: bold;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }
  }
}