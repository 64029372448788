@import "../../colors.scss";
@import "../../mixins.scss";
@import "../../typography.scss";
.generic-page {
  .page-content {
    @extend %container;
    width: 100%;
    align-self: center;
    padding: 30px 15px;
    @media screen and (min-width: 768px) {
      padding: 50px 15px;
    }
    >*,
    p,
    h1,
    h2,
    h3 {
      margin-bottom: 20px;
    }
    h1,
    h2,
    h3 {
      font-weight: bolder;
      font-family: $headingFont;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 20px;
      color: $darkBlue;
      margin-top: 40px;
    }
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
      font-family: $sura;
    }
    a {
      cursor: pointer;
      color: $darkBlue;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      margin-left: 30px;
      li {
        height: auto;
        font-family: $sura;
        font-size: 16px;
        font-weight: bolder;
        list-style-type: disc;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    section {
      margin-top: 20px;
    }
    .subheader {
      font-family: $newsCycle;
      font-size: 22px;
      margin-top: -10px;
      letter-spacing: 0.15rem;
    }
  }
}